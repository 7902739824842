// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
const CKEditor = require("@ckeditor/ckeditor5-vue2"); // eslint-disable-line @typescript-eslint/no-var-requires
require("@ckeditor/ckeditor5-build-classic/build/translations/ja");

@Component({
  components: {
    ckeditor: CKEditor.component,
  },
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public domain: string = process.env.VUE_APP_API_URL as string;
  editor = require("@ckeditor/ckeditor5-build-classic"); // eslint-disable-line @typescript-eslint/no-var-requires
  public editorConfig = {
    language: "ja",
    removePlugins: ["ImageUpload"],
  };
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
    this.model.pageLoading = true;
    this.loadingOn();
    let response: any;
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ChannelPageCreate/Main`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.model.channelId = response.channelId;
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();
    this.model.pageLoading = false;
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    if (!auto && !inputing) {
      this.model.saveButtonCompleted = false;
    }
    let result = true;
    let input: any;
    let errors: any;
    if (target == "all" || target == "url") {
      input = this.model.url;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
        input.value = this.hankaku2Zenkaku(input.value);
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("必須項目です"));
      } else if (input.value.match(/^([A-Za-z0-9]){1}/g) == null) {
        errors.push(
          new model.Error("URLの最初の一文字には記号は使えません")
        );
      } else if (input.value.match(/([A-Za-z0-9]){1}$/g) == null) {
        errors.push(
          new model.Error("URLの最後の一文字には記号は使えません")
        );
      } else if (input.value.length < input.minLength) {
        errors.push(
          new model.Error(`${input.minLength}文字以上で入力してください`)
        );
      } else if (input.value.match(/^([A-Za-z0-9_-])+$/g) == null) {
        errors.push(
          new model.Error(
            "対応していない文字列が使用されています（アルファベット大文字・小文字、ハイフン、アンダーバーが使用可能）"
          )
        );
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "title") {
      input = this.model.title;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
        input.value = this.hankaku2Zenkaku(input.value);
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("必須項目です"));
      } else if (input.value.length < input.minLength) {
        errors.push(
          new model.Error(`${input.minLength}文字以上で入力してください`)
        );
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "body") {
      input = this.model.body;
      errors = new Array<model.Error>();
      //
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    return result;
  }
  public async save() {
    this.model.pageLoading = true;
    this.model.saveButtonActive = false;
    this.model.saveButtonLoading = true;
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.saveButtonLoading = false;
      this.model.saveButtonActive = true;
      this.model.pageLoading = false;
      return;
    }
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ChannelPageCreate/Save`,
        {
          url: this.model.url.value,
          title: this.model.title.value,
          body: this.model.body.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.model.saveButtonCompleted = true;
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();
    await this.sleep(2000);
    this.$router.push({
      name: "CreatorChannelPages",
      params: {},
    });
    this.model.saveButtonActive = true;
    this.model.saveButtonLoading = false;
    this.model.pageLoading = false;
  }
}
